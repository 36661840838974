import 'core-js';
import 'zone.js/dist/zone';
import 'url-polyfill';

((): void => {
    if (!Element.prototype.matches) {
        // tslint:disable-next-line:no-string-literal
        Element.prototype.matches = Element.prototype['msMatchesSelector'] || Element.prototype.webkitMatchesSelector;
    }
})();
